.headerDropdown {
  @apply w-full relative flex flex-col sm:block items-center;
}

.dropdownMenu {
  @apply transition-opacity duration-100 ease-in-out;

  opacity: 0;
  visibility: hidden;
  display: none;
  margin-bottom: 0.5rem;

  &--open {
    opacity: 1;
    visibility: visible;
    display: block;
  }
}

.dropdownMenu--nav {
  left: -8px;
  @screen sm {
    min-width: 200px;
    position: absolute;
    top: 100%;
    z-index: 50;
  }
}

.dropdownMenu--profile {
  right: 0;
  min-width: 240px;

  position: absolute;
  top: 100%;
  z-index: 50;
}

.dropdownContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  border-radius: 16px;

  > a {
    width: fit-content;
  }
}

.dropdownContents--box {
  box-shadow: 0px 4px 16px rgba(45, 45, 66, 0.24);
  padding: 20px 16px 16px;
  margin-bottom: 0;
  align-items: start;
  gap: 1rem;

  background-color: var(--body-bg);
  :global(.dark) & {
    background-color: var(--ui-tertiary);
  }
}
