.Header {
  @apply fixed top-0 inset-x-0 z-40;

  & header {
    @apply relative border-b border-dark-ui-tertiary;
    padding: 0 var(--Layout--padding-horizontal);

    transition: background-color 100ms linear;

    &:not(.transparent) {
      background-color: var(--body-bg);
    }

    :global(.light) & {
      @apply border-light-ui-tertiary;
    }

    .Header__inner {
      @apply mx-auto flex h-20 items-center justify-between gap-8;
      max-width: var(--Content-max-width);

      .leftGroup {
        @apply flex items-center gap-4;
      }

      .rightGroup {
        @apply flex items-center gap-2;

        /* The theme toggle that appears in the header */
        > .ThemeToggle {
          @apply p-2;

          /* On screens 639px and smaller, hide the toggle from the header */
          @media screen and (max-width: 639px) {
            display: none;
          }
        }
      }
    }
  }
}

.WrongNetwork {
  @apply h-8 leading-8 w-full text-center text-sm;
  background-color: var(--color-red-500);
  color: var(--color-dark-content-primary);
}

.ProofGlyph {
  fill: var(--content-primary);

  &:hover {
    fill: url(#gradient-proof-375);
  }
}

.ThemeToggle {
  @apply flex gap-2 items-center;

  /* On screens 640px and larger, theme toggle appears in the header. Hide the label. */
  .ThemeToggle__label {
    @media screen and (min-width: 640px) {
      display: none;
    }
  }
}
