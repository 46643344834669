@tailwind components;

@layer components {
  .hoverable {
    cursor: pointer;

    &:hover {
      fill: var(--ui-text-primary-text);
    }
  }

  .down {
    transform: rotate(180deg);
  }

  .left {
    transform: rotate(270deg);
  }

  .right {
    transform: rotate(90deg);
  }

  .primary {
    fill: var(--ui-text-primary-text);
  }

  .secondary {
    fill: var(--ui-text-secondary-text);
  }

  .inactive {
    fill: var(--ui-text-inactive-text);
  }

  .disabled {
    fill: var(--ui-text-disabled-text);
  }
}
