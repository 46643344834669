.SearchResults {
  @apply absolute z-30 overflow-y-auto top-full left-0 right-auto mt-6 bg-white rounded-2xl shadow-lg p-4;

  min-width: 400px;
  max-height: 440px;

  :global(.dark) & {
    background-color: var(--color-dark-ui-tertiary);
  }

  @media screen and (max-width: 639px) {
    left: -60px;
    min-width: calc(100vw - 48px);
  }
}

.SearchIcon {
  fill: var(--content-primary);
  fill-rule: evenodd;
}

.rightIcon {
  fill: var(--content-primary);
  color: var(--content-primary);
}
