@keyframes glow1Anim {
  0% {
    opacity: 0.6;
    transform: scale(100%, 100%) translate(0%, 0%);
  }

  25% {
    transform: translate(-8%, 34%);
  }

  50% {
    opacity: 0.8;
    transform: scale(150%, 87%) translate(-16%, -7%);
  }

  75% {
    transform: translate(-9%, -27%);
  }

  100% {
    opacity: 0.6;
    transform: scale(100%, 100%) translate(0%, 0%);
  }
}

@keyframes glow2Anim {
  0% {
    opacity: 0.75;
    transform: scale(100%, 100%) translate(0%, 0%);
  }

  50% {
    opacity: 0.9;
    transform: scale(80%, 160%) translate(-55%, -10%);
  }

  100% {
    opacity: 0.75;
    transform: scale(100%, 100%) translate(0%, 0%);
  }
}

/* constrain the width of the glows so they dont exceed the dimensions of viewport,
    otherwise they will cause other contents on the page to shrink on small viewports */
.glowContainer {
  @apply absolute overflow-x-hidden right-0 -top-8 sm:-top-12 min-h-[100vh] h-full w-full;
}

.glowContainer--grey {
  .glow1,
  .glow2 {
    background: radial-gradient(
      100.05% 103.41% at 0% -3.41%,
      rgba(221, 227, 237, 0.6) 0%,
      rgba(225, 229, 235, 1) 100%
    );
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

.glowContainer--purple {
  .glow1 {
    @apply bg-gradient-to-r from-fuschia-500 to-blue-500;
  }
  .glow2 {
    @apply bg-gradient-to-r from-[#fb11b8] to-blue-500;
  }
}

.glow {
  @apply absolute top-0 right-0 -z-0;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.glow1 {
  composes: glow;

  filter: blur(200px);
  animation-duration: 23s;
  animation-name: glow1Anim;

  width: 616px;
  height: 616px;
  margin-top: -344px;
  margin-right: -376px;

  @screen sm {
    margin-top: -100px;
    margin-right: -182px;
  }
}

.glow2 {
  composes: glow;

  filter: blur(140px);
  animation-duration: 19s;
  animation-name: glow2Anim;

  width: 400px;
  height: 400px;
  margin-top: 0px;
  margin-right: 0px;
}

@keyframes radarAnim {
  0% {
    transform: scale(0%, 0%);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 0.05;
  }

  100% {
    opacity: 0;
    transform: scale(100%, 100%);
  }
}

.radar {
  @apply absolute z-0;

  width: 1600px;
  height: 1600px;
  top: -800px;
  right: -800px;

  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 1) 66%,
    rgba(255, 255, 255, 0) 72%
  );

  animation-duration: 16s;
  animation-name: radarAnim;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
