.searchResultSection:not(:last-child) {
  @apply pb-4 mb-4;
  border-bottom: 1px solid var(--ui-secondary);
}

.searchSectionLabel {
  @apply mb-2 block;
  color: var(--content-secondary);
}

.searchResult {
  @apply p-2 block items-center;

  &--selected {
    @apply bg-light-ui-tertiary rounded-md;

    :global(.dark) & {
      @apply bg-dark-ui-secondary;
    }
  }
}

.thumbnailContainer {
  @apply flex items-center;
  width: 54px;
  height: 54px;
}

.byLineWithAvatar {
  @apply flex items-center gap-1;
}
