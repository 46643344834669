@media screen and (max-width: 639px) {
  .navItem {
    width: 100%;
    @apply flex items-center justify-center pb-4;

    border-bottom: 1px solid var(--ui-secondary);
  }

  .navItem--open {
    padding-bottom: 0;
  }
}
