.Footer {
  @apply mb-12 mt-20 sm:mt-32;
  padding: 0 var(--Layout--padding-horizontal);

  & > div {
    @apply mx-auto;
    max-width: var(--Content-max-width);
  }
}

.content {
  @apply mt-6 flex flex-col md:flex-row justify-between items-start;
}

.dividerContainer {
  @apply flex items-center w-full;
}

.globe {
  @apply w-6 h-6 mx-1.5;
  fill: var(--ui-secondary);
}

.wordmarkContainer {
  @apply mb-6 md:mb-0 w-full md:w-auto;
}

.wordmark {
  @apply w-24 mb-1;
  fill: var(--content-secondary);
}

.navigation {
  @apply flex md:items-start flex-wrap md:gap-[80px] lg:gap-[120px] justify-between w-full md:w-auto;
}

.iconGroup {
  @apply flex items-center mt-6 md:mt-0 gap-2 w-full md:w-auto;
}

.icon {
  @apply w-6;
  fill: var(--content-secondary);

  &:hover {
    fill: var(--content-primary);
  }
}
