.MobileNavigationMenu {
  @apply absolute w-full z-30 top-full inset-x-0 px-9 py-8 mt-px;
  @apply transition-opacity duration-200 ease-in-out shadow-md;

  /* we set the max height so its contents can be scrolled */
  max-height: calc(100vh - 81px);
  overflow-y: scroll;

  background-color: var(--body-bg);

  @screen sm {
    @apply hidden px-10;
  }
}

:global(.dark) {
  .MobileNavigationMenu {
    @apply border-b border-dark-ui-secondary;
  }
}
